import React, {PureComponent} from 'react';
import { withPrefix} from 'gatsby-link';
import browserLang from 'browser-lang';

class Redirect extends PureComponent {
    componentDidMount() {
        const langKeys = ['ru', 'en'];
        const {pathname} = this.props.location;

        // Skip build, Browsers only
        if (typeof window !== 'undefined') {
            const detected =
                window.localStorage.getItem('language') ||
                browserLang({
                    languages: langKeys,
                    fallback: 'ru',
                });

            const newUrl = withPrefix(`/${detected}${pathname}`);
            window.localStorage.setItem('language', detected);
            window.location.replace(newUrl);
        }
    }


    render() {
        return <></>;
    }
}

export default Redirect;